import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Backdrop,
  Container,
  CircularProgress,
  Typography,
  Grid,
  Button,
  InputBase,
  NativeSelect,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import 'react-datepicker/dist/react-datepicker.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { useUserAuth } from '../../context/UserAuthContext';
import { useSearch } from '../../context/SearchContext';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import CouponContent from './CouponContent/CouponContent';
import Reports from './Reports/Reports';

import './CouponManagement.css';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const Main = () => {
  const { selectedOloStore, setSelectedOloStore } = useSearch();
  const navigate = useNavigate();

  const { user: firebaseUser, loading: authenticating } = useUserAuth();

  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [showAlert, setShowAlert] = useState('');

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cell: '',
    role: '',
    defaultStore: '',
    storeName: '',
    department: '', // This field is only visible when the role is 'Basecamp'
  });
  const [showReports, setShowReports] = useState(false);
  const [selectedReport, setSelectedReport] = useState('redeemed');

  useEffect(() => {
    if (firebaseUser.getIdToken) {
      handleGetStores();
      handleGetUserInfo();
    }
  }, [firebaseUser]);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/${firebaseUser.email}/info`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      if (response.data.newUser) {
        navigate('/profile');
      } else {
        setFormData({
          ...response.data.userInfo,
        });
        if (response.data.userInfo.role.toLowerCase() === 'direct') {
          // if the direct user has a store, load their default store
          if (response.data.userInfo.selectedStore) {
            setSelectedOloStore(response.data.userInfo.selectedStore);
          } else {
            setSelectedOloStore('all');
          }
          // if the direct user does not have a store, load all stores
          // and let them see the button selector
        } else if (
          response.data.userInfo.role.toLowerCase() === 'store' &&
          response.data.userInfo.defaultStore
        ) {
          setSelectedOloStore(response.data.userInfo.defaultStore);
        } else if (
          response.data.userInfo.role.toLowerCase() === 'basecamp' &&
          !selectedOloStore
        ) {
          setSelectedOloStore('all');
        } else if (
          response.data.userInfo.role.toLowerCase() === 'do' &&
          !selectedOloStore
        ) {
          setSelectedOloStore('all');
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user info from db:', error);
      setLoading(false);
    }
  };

  const handleGetStores = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/user/stores/${firebaseUser.email}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      const updatedStores = response.data.stores;
      updatedStores.unshift({ storeId: 'all', storeName: 'All' });
      setStores(response.data.stores);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores from db:', error);
      setLoading(false);
    }
  };

  const handleViewInvoice = (exportBatchId, invoiceNumber) => {
    navigate(`/olo-invoice/${exportBatchId}/${invoiceNumber}`);
  };

  const handleChangeSelectedReport = async (event) => {
    const selected = event.target.value;
    setSelectedReport(selected);
  };

  const handleShowReports = () => {
    setShowReports(true);
  };

  const handleShowCouponList = () => {
    setShowReports(false);
  };

  const { role } = formData;

  let content;
  if (authenticating) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography style={{ fontFamily: 'Lato', fontSize: 34 }}>
          Offer Code Management
        </Typography>
        <Grid container justifyContent='space-evenly' style={{ marginTop: 16 }}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            {showReports && (
              <FormControl size='small'>
                <InputLabel id='store-label'>Reports</InputLabel>
                <NativeSelect
                  value={selectedReport}
                  onChange={handleChangeSelectedReport}
                  label='Reports'
                  input={<BootstrapInput />}
                >
                  <option value={'redeemed'}>Redeemed Codes</option>
                  <option value={'unused'}>Unused Codes</option>
                  <option value={'common'}>Most Common Mistakes</option>
                  <option value={'most'}>Stores with Most Codes</option>
                </NativeSelect>
              </FormControl>
            )}
          </Grid>
          <Grid
            xs={4}
            container
            justifyContent='flex-end'
            alignContent='center'
          >
            {!showReports ? (
              <Button
                color='info'
                variant='contained'
                onClick={handleShowReports}
              >
                Reports
              </Button>
            ) : (
              <Button
                color='primary'
                variant='contained'
                onClick={handleShowCouponList}
                style={{ height: 'fit-content' }}
              >
                Coupon List
              </Button>
            )}
          </Grid>
        </Grid>
        {!showReports ? (
          <CouponContent
            handleViewInvoice={handleViewInvoice}
            firebaseUser={firebaseUser}
            formData={formData}
            role={role}
            stores={stores}
            selectedOloStore={selectedOloStore}
            setSelectedOloStore={setSelectedOloStore}
          />
        ) : (
          <Reports
            selectedReport={selectedReport}
            role={role}
            formData={formData}
            stores={stores}
            loading={loading}
            setLoading={setLoading}
            firebaseUser={firebaseUser}
            handleViewInvoice={handleViewInvoice}
            selectedOloStore={selectedOloStore}
            setSelectedOloStore={setSelectedOloStore}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='xl'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Main;
