import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Button,
} from '@mui/material';

const OrderTable = ({
  handleViewInvoice,
  rows,
  order,
  orderBy,
  totalCount,
  handleRequestSort,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  page,
  columnMap,
  stableSort,
  getComparator,
}) => {
  const handleSortRequest = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const sortedRows = stableSort(rows, getComparator(order, orderBy));
  const columns = Object.keys(columnMap);

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      <Table>
        {/* Dynamic Table Header */}
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={handleSortRequest(column)}
                >
                  {columnMap[column]}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Dynamic Table Body */}
        <TableBody>
          {sortedRows.map((rowData, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: 'inherit',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            >
              {columns.map((column) => {
                const cellData = rowData[column];
                if (column === 'originalOrder' || column === 'redeemedOrder') {
                  return (
                    <TableCell align='left' key={column}>
                      <Button
                        onClick={() => handleViewInvoice(0, rowData[column])}
                        disabled={!cellData}
                      >
                        {cellData || '-'}
                      </Button>
                    </TableCell>
                  );
                }
                return <TableCell key={column}>{cellData || '-'}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Table Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default OrderTable;
