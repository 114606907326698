import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  InputBase,
  NativeSelect,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const UserProfileForm = ({
  formData,
  handleCreateUser,
  handleUpdateUser,
  stores,
  setFormData,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const requiredFields = getRequiredFields(formData);
    const isAllFieldsFilled = requiredFields.every(
      (fieldName) =>
        formData[fieldName] !== '' && formData[fieldName] !== undefined
    );
    setIsFormValid(isAllFieldsFilled);
  }, [formData]);

  const getRequiredFields = (formData) => {
    switch (formData.role) {
      case 'Store':
        return Object.keys(formData).filter(
          (fieldName) => fieldName !== 'department' && fieldName !== 'store'
        );
      case 'Basecamp':
        return Object.keys(formData).filter(
          (fieldName) =>
            fieldName !== 'store' &&
            fieldName !== 'defaultStore' &&
            fieldName !== 'storeName' &&
            fieldName !== 'selectedStore'
        );
      default:
        return Object.keys(formData).filter(
          (fieldName) =>
            fieldName !== 'department' &&
            fieldName !== 'store' &&
            fieldName !== 'defaultStore' &&
            fieldName !== 'storeName' &&
            fieldName !== 'selectedStore'
        );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'defaultStore') {
      console.log(value);
      const { storeName } = stores.find(
        (s) => String(s.storeId) === String(value)
      );
      setFormData({
        ...formData,
        [name]: value,
        storeName,
        selectedStore: value,
      });
    } else if (name === 'role') {
      switch (value) {
        case 'Basecamp':
          setFormData({
            ...formData,
            [name]: value,
            defaultStore: '',
            store: '',
            storeName: '',
            selectedStore: '',
          });
          break;
        case 'DO':
          setFormData({
            ...formData,
            [name]: value,
            defaultStore: '',
            store: '',
            storeName: '',
            selectedStore: '',
            department: '',
          });
          break;
        default:
          setFormData({
            ...formData,
            [name]: value,
            department: '',
          });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleOnChange = (text) => {
    var cleaned = ('' + text).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '',
        number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
          ''
        );

      setFormData({
        ...formData,
        cell: number,
      });

      return;
    }
    setFormData({
      ...formData,
      cell: text,
    });
  };
  let forceStoreUser = true;
  if (
    (formData.role && formData.role === 'DO') ||
    (formData.role && formData.role === 'Basecamp')
  ) {
    forceStoreUser = false;
  }
  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            label='First Name'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            label='Last Name'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Cell Phone'
            name='cell'
            value={formData.cell}
            onChange={(e) => handleOnChange(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Role*</InputLabel>
            <NativeSelect
              disabled={forceStoreUser}
              name='role'
              value={formData.role}
              onChange={handleChange}
              label='role'
              required
              input={<BootstrapInput />}
            >
              <option value='Store'>Store</option>
              <option value='Direct'>Direct</option>
              <option value='DO'>DO</option>
              <option value='Basecamp'>Basecamp</option>
            </NativeSelect>
          </FormControl>
        </Grid>
        {formData.role === 'Basecamp' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Department*</InputLabel>
              <NativeSelect
                name='department'
                value={formData.department}
                onChange={handleChange}
                label='department'
                required
                input={<BootstrapInput />}
              >
                <option value=''></option>
                <option value='Accounting'>Accounting</option>
                <option value='Culinary'>Culinary</option>
                <option value='Development'>Development</option>
                <option value='Executive'>Executive</option>
                <option value='Facilities'>Facilities</option>
                <option value='Finance'>Finance</option>
                <option value='Guest Relations'>Guest Relations</option>
                <option value='IT'>IT</option>
                <option value='Marketing'>Marketing</option>
                <option value='NSO'>NSO</option>
                <option value='Operations Leadership'>
                  Operations Leadership
                </option>
                <option value='Operations Services'>Operations Services</option>
                <option value='Payroll'>Payroll</option>
                <option value='Supply Chain'>Supply Chain</option>
                <option value='Talent'>Talent</option>
                <option value='Team Member Support'>Team Member Support</option>
                <option value='Training'>Training</option>
              </NativeSelect>
            </FormControl>
          </Grid>
        )}
        {formData.role === 'Store' && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Store*</InputLabel>
              <NativeSelect
                disabled={stores?.length === 0}
                value={formData.selectedStore}
                onChange={handleChange}
                label='store*'
                name='defaultStore'
                input={<BootstrapInput />}
              >
                {stores?.map((store, i) =>
                  i === 0 ? (
                    <option key={store.storeId} value={store.storeId}>
                      {store.storeName}
                    </option>
                  ) : (
                    <option key={store.storeId} value={store.storeId}>
                      {store.storeName}
                    </option>
                  )
                )}
              </NativeSelect>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            disabled={!isFormValid}
            type='submit'
            variant='contained'
            color='primary'
            size='large'
            style={{ margin: 4 }}
            onClick={formData.userId ? handleUpdateUser : handleCreateUser}
          >
            {formData.userId ? 'Update' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserProfileForm;
