import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  Box,
  TextField,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Table from './Table/Table';
import FilterActionBar from './FilterActionBar/FilterActionBar';

const columnDefinitions = {
  redeemed: {
    originalOrder: 'Original Order',
    redeemedOrder: 'Redeemed Order',
    storeName: 'Store',
    menuItem: 'Menu Item',
    timePlaced: 'Time Redeemed',
  },
  unused: {
    originalOrder: 'Original Order',
    storeName: 'Store',
    reason: 'Reason',
    menuItem: 'Menu Item',
    dateSent: 'Date Sent',
  },
  common: {
    reason: 'Most Common Mistakes / Reasons',
    reasonCount: 'Occurrences',
  },
  most: {
    storeName: 'Store',
    codeCount: 'Total Codes Generated',
  },
};

const Reports = ({
  firebaseUser,
  role,
  formData,
  stores,
  loading,
  selectedReport,
  setLoading,
  handleViewInvoice,
  selectedOloStore,
  setSelectedOloStore,
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('');
  const [searchField, setSearchField] = useState('oloRef');
  const [searchQuery, setSearchQuery] = useState('');
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    getReportData();
  }, [selectedReport]);

  useEffect(() => {
    if (firebaseUser.getIdToken && formData) {
      if (searchQuery.length > 0 || startDate || endDate) {
        handleSearch();
      } else if (selectedOloStore) {
        getReportData();
      }
    }
  }, [selectedOloStore, firebaseUser]);

  const getReportData = async () => {
    setLoading(true);

    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/hd-api/olo/report/${selectedReport}/store=${selectedOloStore}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      if (!response.data.err) {
        // Update state with the filtered data
        setReportData(response.data);
        setTotalCount(response.data.length);
      } else {
        setReportData([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error(`Error fetching data for ${selectedReport} report:`, error);
      setReportData([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery && !startDate && !endDate) {
      // If search query is empty, fetch the entire report again
      return getReportData();
    }

    setLoading(true);

    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      // Fetch filtered data from the API using search field and query
      const response = await axios.get(
        `/hd-api/olo/report/${selectedReport}/store=${selectedOloStore}`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          params: {
            searchField,
            searchQuery,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
          },
        }
      );
      console.log(response.data);
      if (!response.data.err) {
        // Update state with the filtered data
        setReportData(response.data);
        setTotalCount(response.data.length);
      } else {
        setReportData([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error(
        `Error fetching filtered data for ${selectedReport} report:`,
        error
      );
      setReportData([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    getReportData();
  };

  const handleSortRequest = (event, property) => {
    const isAscending = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAscending ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy.includes('time')) {
      return new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const stableSort = (array, comparator) => {
    return array
      ?.map((el, index) => [el, index])
      .sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      })
      .map((el) => el[0]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearchField = (e) => {
    const { value } = e.target;
    setSearchQuery('');
    setStartDate();
    setEndDate();
    setSearchField(value);
  };

  const handleChangeSelectedStore = (e) => {
    setSelectedOloStore(e.target.value);
  };
  const isStoreFilterVisible =
    role === 'DO' ||
    role === 'Basecamp' ||
    (role === 'Direct' && !formData.selectedStore);

  let searchInput = (
    <TextField
      sx={{ backgroundColor: 'white', marginTop: 1 }}
      size='small'
      value={searchQuery}
      name='searchQuery'
      onChange={handleSearchQueryChange}
      onKeyPress={handleKeyPress}
    />
  );
  if (searchField === 'dateSent') {
    searchInput = (
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText='Start Date'
          customInput={
            <TextField
              size='small'
              style={{ backgroundColor: 'white', marginTop: 8 }}
            />
          }
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText='End Date'
          customInput={
            <TextField
              size='small'
              style={{ backgroundColor: 'white', marginTop: 8 }}
            />
          }
        />
      </div>
    );
  }

  const currentColumns = columnDefinitions[selectedReport] || {};

  return (
    <div>
      <FilterActionBar
        selectedReport={selectedReport}
        searchField={searchField}
        handleSearch={handleSearch}
        handleChangeSearchField={handleChangeSearchField}
        searchInput={searchInput}
        stores={stores}
        showStoreFilter={isStoreFilterVisible}
        handleClear={handleClear}
        selectedOloStore={selectedOloStore}
        handleChangeSelectedStore={handleChangeSelectedStore}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Accordion
          defaultExpanded
          style={{ width: '100%', backgroundColor: '#f8f8f8', marginBottom: 0 }}
        >
          <AccordionDetails>
            <Table
              order={sortOrder}
              totalCount={totalCount}
              orderBy={sortBy}
              handleRequestSort={handleSortRequest}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={page}
              columnMap={currentColumns}
              rows={reportData}
              handleViewInvoice={handleViewInvoice}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Reports;
